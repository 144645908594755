import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const Products = ({owner}) => {
  const [items, setItems] = useState([]);
  const [date, setDate] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // Domyślne sortowanie rosnąco

  const fetchItems = async () => {
    try {

      const response = await axios.get(`${apiUrl}/products/${owner}`);

      const json = await response.data;
      const items = json.items
      const date = json.date
      setItems(items);
      setDate(date);
    } catch (error) {
      // Handle errors gracefully, e.g., display error messages
      console.error('Error fetching offers:', error);
    }
  };

const handleCheckboxChange = async (id, isChecked) => {
  try {
    const url = `${apiUrl}/products/${id}/${isChecked ? 'add' : 'remove'}/${owner}`;
    const response = await axios.put(url);

    if (response.status !== 200) {
      throw new Error(`API call failed with status ${response.status}`);
    }

    const updatedItem = response.data; // Pobrany element z odpowiedzi HTTP

    // Aktualizacja listy items
    const updatedItems = items.map((o) =>
      o.id === id ? updatedItem : o
    );

    setItems(updatedItems);
  } catch (error) {
    if (error.response && error.response.status === 400) {
      toast.error("Produkt już jest niedostępny");
      // Usuń element z listy items
      const updatedItems = items.filter((o) => o.id !== id);
      setItems(updatedItems);
    } else {
      // Handle other errors gracefully, e.g., display error messages
      console.error(`Error updating product status:`, error);
      toast.error("An error occurred while updating the product status.");
    }
  }
};

  const handleSortByDiscount = () => {
    const sortedItems = [...items].sort((a, b) => {
      const discountA = (a.normal_price - a.price) / a.normal_price;
      const discountB = (b.normal_price - b.price) / b.normal_price;

      if (sortOrder === 'asc') {
        return discountA - discountB;
      } else {
        return discountB - discountA;
      }
    });

    setItems(sortedItems);
    // Zmiana kolejności sortowania
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handlePriorityChange = async (id, newPriority) => {
    try {
      const url = `${apiUrl}/products/${id}/priority/${newPriority}`;
      const response = await axios.put(url);

      if (response.status !== 200) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const updatedItem = response.data;

      const updatedItems = items.map((o) => (o.id === id ? updatedItem : o));
      setItems(updatedItems);
    } catch (error) {
      console.error(`Error updating priority:`, error);
    }
  };

  const handlePriorityInputChange = (id, value) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, priority: value } : item
    );
    setItems(updatedItems);
  };

  const handlePriorityInputBlur = (id, value) => {
    handlePriorityChange(id, value);
  };

  const handlePriorityInputKeyPress = (e, id, value) => {
    if (e.key === 'Enter') {
      handlePriorityChange(id, value);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <h1>Products</h1>
      <button onClick={handleSortByDiscount}>
        Sort by Discount {sortOrder === 'asc' ? '▲' : '▼'}
      </button>
      <div>
        Last update: {date}
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            {owner ==='tmcode' && <th>HU</th>}
            <th>Name</th>
            <th>Group</th>
            <th>Price</th>
            <th>Normal Price</th>
            <th>Discount</th>
            <th>Description</th>
            <th>Owner</th>
            <th>Added</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              {owner ==='tmcode' && <th>{item.id}</th>}
              <td>{item.name}</td>
              <td>{item.group}</td>
              <td>{item.price}</td>
              <td>{item.normal_price}</td>
              <td>{((item.normal_price - item.price) / item.normal_price) * 100}</td>
              <td>{item.description}</td>
              <td>{item.owner}</td>
              <td>
                <input
                  type="checkbox"
                  checked={item.owner===owner}
                  onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={item.priority}
                  onChange={(e) => handlePriorityInputChange(item.id, e.target.value)}
                  onBlur={(e) => handlePriorityInputBlur(item.id, e.target.value)}
                  onKeyPress={(e) => handlePriorityInputKeyPress(e, item.id, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Products;
